import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Ul = styled.ul`
  list-style-type: none;
`;

const Li = styled.li`
  & {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  &::before {
    content: '👉 ';
  }

  & > * {
    padding-left: 10px;
    border: none;
  }
`;

export default function Home() {
  return (
    <Layout>
      <p>
        Welcome to my page. This site is still in progress, but here are the
        most useful links:
      </p>
      <Ul>
        <Li>
          <Link to="/about">About me</Link>
        </Li>
        <Li>
          <Link to="/contact">Contact</Link>
        </Li>
      </Ul>
      <Helmet>
        <title>Home</title>
      </Helmet>
    </Layout>
  );
}
